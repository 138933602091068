import logo from "./images/Logo Nossa FM 105.png";
import logoOliveira from "./images/Logo Nossa FM 96 1.png";
import logoTv from "./images/Logo NTV icone 1900px.png";
import logoPatosja from "./images/Logo PatosJa icone 1900px.png";
import bg from "./images/bg.png";

function App() {
  return (
    <div className="App">
      <img src={bg} alt="background" className="bg" />
      <div className="logos-nfm">
        <a href="https://nossafm105.com" className="link">
          <img src={logo} alt="logo nfm"></img>
          <p>Patos de Minas</p>
        </a>
        <a
          href="https://nossafm96.com/"
          className="link"
        >
          <img src={logoOliveira} alt="logo nfm"></img>
          <p>Oliveira</p>
        </a>
      </div>
      <div className="logos-outros">
        <a href="https://ntv.tv.br">
          <img src={logoTv} alt="logo tv" />
        </a>
        <a href="https://patosja.com.br">
          <img src={logoPatosja} alt="logo tv" />
        </a>
      </div>
    </div>
  );
}

export default App;
